export const TEMPLATE_CODE_CACHE = 'templateCodeCache';
export const CV_LANGUAGE_CACHE = 'cvLanguageCache';
export const CV_DATA_LOCAL_CACHE = 'cvDataLocalCache';
export const COVERLETTER_DATA_LOCAL_CACHE = 'coverLetterDataLocalCache';
export const APPLICANT_IMAGE_CACHE = 'applicantImageCache';
export const CUSTOM_CAPTIONS_CACHE = 'customCaptions';
export const STYLE_CACHE = 'styleCache';
export const SIGNATURE_CACHE = 'signatureCache';
export const PICTURE_SELECTOR_SETTINGS_CACHE = '_pictureselector';
export const REFERENCES_CACHE = 'referencesCache';

export const STATIC_ASSET_URL = 'https://designs.cvcollage.com';
export const apiBaseUrl = 'https://api.cvcollage.com/v1';

export const ACTIVATION_CVDATA_CACHE = 'ACTIVATION_CVDATA_CACHE';
export const ACTIVATION_TEMPLATE_CODE_CACHE = 'ACTIVATION_TEMPLATE_CODE_CACHE';
export const ACTIVATION_CV_LANGUAGE_CACHE = 'ACTIVATION_CV_LANGUAGE_CACHE';
export const ACTIVATION_DOC_TYPE = 'ACTIVATION_DOC_TYPE';

export const LOGIN_SIGNUP_REDIRECT_URL = 'loginSignupRedirect';

export const EXPERIMENTAL_MODE_PARAM = 'experimentalMode';

export const ONBOARDING_COMPLETED_CACHE = '__onboarding_completed_cache';

window.apiBaseURL=apiBaseUrl;
window.TEMPLATE_CODE_CACHE = TEMPLATE_CODE_CACHE;
window.CV_LANGUAGE_CACHE = CV_LANGUAGE_CACHE;
window.CV_DATA_LOCAL_CACHE = CV_DATA_LOCAL_CACHE;

window.COUPON_CACHE="couponCache";

//cache variables for activations page
window.ACTIVATION_CVDATA_CACHE = "ACTIVATION_CVDATA_CACHE";
window.ACTIVATION_TEMPLATE_CODE_CACHE = "ACTIVATION_TEMPLATE_CODE_CACHE";
window.ACTIVATION_CV_LANGUAGE_CACHE = "ACTIVATION_CV_LANGUAGE_CACHE";