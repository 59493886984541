export const GENERATE_DOCUMENT_MODE = 'GENERATE_DOCUMENT';
export const JUST_UPGRADE_MODE = 'JUST_UPGRADE';

const ACTIVATION_UPGRADE_MODE_CACHE = 'activationUpgradeModeCache';

/**
 * @param {'GENERATE_DOCUMENT' | 'JUST_UPGRADE'} upgradeMode 
 */
export function setUpgradeMode(upgradeMode) {
    if(upgradeMode !== JUST_UPGRADE_MODE && upgradeMode !== GENERATE_DOCUMENT_MODE) {
        throw `Invalid upgrade mode ${upgradeMode}`;
    }

    sessionStorage.setItem(ACTIVATION_UPGRADE_MODE_CACHE, upgradeMode);
}

/**
 * @returns {string | null}
 */
export function consumeUpgradeMode() {
    const upgradeMode =  sessionStorage.getItem(ACTIVATION_UPGRADE_MODE_CACHE);
    sessionStorage.removeItem(ACTIVATION_UPGRADE_MODE_CACHE);
    return upgradeMode;
}